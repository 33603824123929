<template>
  <transition>
    <div id="app">
      <v-app>
        <v-main>
          <router-view />
        </v-main>
      </v-app>
    </div>
  </transition>
</template>

<script>
export default {};
</script>
