import "core-js/stable";
import Vue from "vue";
import App from "./App.vue";
import Router from "vue-router";
import { router } from "./router";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/dist/vuetify.min.css";
import Vuetify from "vuetify";
import OurBusyIndicator from "./components/OurBusyIndicator.vue";

var vuetifyOpts = {
  theme: {
    primary: "#16A086",
    secondary: "#303641",
    accent: "#EDEDED",
    error: "#E63939",
  },
  options: {
    customProperties: true,
  },
  icons: {
    iconfont: "mdi",
  },
};
Vue.component("our-busy-indicator", OurBusyIndicator);

Vue.use(Vuetify);
Vue.use(Router);

new Vue({
  el: "#vue-app",
  router,
  vuetify: new Vuetify(vuetifyOpts),
  render: (h) => h(App),
});
