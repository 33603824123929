import Home from "./views/Home.vue";
import SignIn from "./views/SignIn.vue";
import SignOut from "./views/SignOut.vue";
import Unauthorised from "./views/Unauthorised.vue";
import { state } from "./store/users/state";

import Router from "vue-router";

export const router = new Router({
  routes: [
    {
      path: "/",
      component: Home,
      meta: { mustBeSignedIn: true },
    },
    {
      path: "/sign-in",
      component: SignIn,
      meta: { mustNotBeSignedIn: true },
    },
    {
      path: "/sign-out",
      component: SignOut,
    },
    {
      path: "/401",
      component: Unauthorised,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.mustNotBeSignedIn) &&
    state.user.isSignedIn
  ) {
    next("/");
  } else if (
    to.matched.some((record) => record.meta.mustBeSignedIn) &&
    !state.user.isSignedIn
  ) {
    next("/sign-in");
  } else {
    next();
  }
});
