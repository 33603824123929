<template>
  <v-container>
    <v-layout>
      <v-flex xs12 sm10 offset-sm1 md8 offset-md2 lg6 offset-lg3 xl4 offset-xl4>
        <div class="text-sm-center mb-3 pt-4">
          <img
            src="/assets/img/aus-govt-logo-centered.png"
            alt="Australian Government"
            height="150px"
          />
        </div>
        <div class="text-h4 text-sm-center mb-3">PRISMS API</div>
        <our-busy-indicator :show="!loaded" />
        <v-flex xs12>
          <v-card>
            <v-list>
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline font-weight-light mb-1"
                    >Welcome {{ name }}</v-list-item-title
                  >
                  <v-list-item-subtitle class="mb-5"
                    >You are logged on the
                    <strong>{{ environment }}</strong>
                    environment.</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    >Access the resources below to get started using the PRISMS
                    API.</v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click.stop="navigateTo('/resources')">
                <v-list-item-icon>
                  <v-icon>help</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>API Developer Resources</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="(apiLink, i) in apiLinks"
                :key="i"
                @click.stop="navigateTo(apiLink.url)"
              >
                <v-list-item-icon>
                  <v-icon v-text="apiLink.icon">{{ apiLink.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="getFullText(apiLink)"
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-card-actions>
              <v-col>
                <v-row justify="end">
                  <v-btn
                    color="primary"
                    :disabled="isSigningOut"
                    @click.stop="signOut()"
                    >Sign out</v-btn
                  >
                </v-row>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { state } from "../store/users/state";
import { userService } from "../store/users/service";

export default {
  data() {
    return {
      loaded: true,
      environment: state.user.details.environment,
      name: state.user.details.userName,
      items: [
        {
          icon: "developer_board",
          url: "/resources/esos-agency-api/v1.html",
          testOnly: false,
          visible: false,
          api: {
            service: "/api-summary",
            environment: null,
            name: null,
            version: null,
          },
        },
      ],
      testingEnvironments: ["Local", "Development", "Test"],
      isSigningOut: false,
    };
  },
  methods: {
    signOut() {
      this.loaded = false;
      this.isSigningOut = true;
      this.isSigningOut = false;
      userService.logout();
      this.$router.push("/sign-in");
      this.loaded = true;
    },
    navigateTo(url) {
      axios
        .get(url, { headers: { Authorization: this.getAuthorizationBearer() } })
        .then((response) => (window.location.href = url));
    },
    getAuthorizationBearer() {
      return `Bearer ${state.user.details.accessToken}`;
    },
    getFullText(info) {
      if (info.api && info.api.name)
        return `${info.api.name} v${info.api.version} (${info.api.environment})`;
      return info.text ? info.text : null;
    },
    loadApiInfo(info) {
      if (!info || !info.api) return;
      axios
        .get(info.api.service, {
          headers: { Authorization: this.getAuthorizationBearer() },
        })
        .then((response) => {
          info.api.environment = response.data.environment;
          info.api.name = response.data.name;
          info.api.version = response.data.version;
          info.visible = true;
        });
    },
  },
  computed: {
    isProduction() {
      return this.environment == "Production" || this.environment == null;
    },
    allowTesting() {
      return this.testingEnvironments.includes(this.environment);
    },
    apiLinks() {
      return this.items.filter(
        (item) => item.visible && (item.testOnly ? this.allowTesting : true)
      );
    },
  },
  mounted: function () {
    this.items.forEach(this.loadApiInfo);
  },
};
</script>
