<!-- 
Sign-in page.
Users sign in using PRISMS credentials.
-->
<template>
  <v-container>
    <v-layout>
      <v-flex xs12 sm10 offset-sm1 md8 offset-md2 lg6 offset-lg3 xl4 offset-xl4>
        <div class="text-sm-center mb-3 pt-4">
          <img
            src="/assets/img/aus-govt-logo-centered.png"
            alt="Australian Government"
            height="150px"
          />
        </div>
        <h1 class="text-sm-center display-1 mb-3 mt-2">
          PRISMS API
        </h1>
        <p class="mb-3">
          This site provides documentation for using the
          <i>PRISMS API</i>. If you are authorised to use the
          <i>PRISMS API</i> then you can sign-in with your PRISMS credentials
          below.
        </p>
        <sign-in-panel />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import SignInPanel from "../components/SignInPanel.vue";

export default {
  name: "SignIn",
  components: { SignInPanel },
};
</script>
