import { userService } from "./service";

let storedUser = null;
try {
  storedUser = JSON.parse(localStorage.getItem("user"));

  if (storedUser) {
    userService.setAuthForAllAxiosRequests();
  }
} catch (e) {
  storedUser = null;
}

export const state = {
  user: {
    details: storedUser,
    isSignedIn: storedUser ? true : false,
    environment: storedUser?.environment ?? "Production",
    env: storedUser?.environmentInfo,
    isProduction: storedUser?.environmentInfo?.isProduction ?? true,
  },
};
