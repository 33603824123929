import { state } from "./state";
import { router } from "../../router";
import axios from "axios";

let interceptorFor401s;

export const userService = {
  login,
  logout,
  setAuthForAllAxiosRequests,
  removeAuthFromAxiosRequests,
};

function login(details) {
  localStorage.setItem("user", JSON.stringify(details));
  state.user.details = details;
  state.user.isSignedIn = true;
  createCookie("prismsapi_token", details.accessToken, 24);
}

function logout() {
  localStorage.removeItem("user");
  removeAuthFromAxiosRequests();
  state.user.details = null;
  state.user.isSignedIn = false;
  deleteCookie("prismsapi_token");
}

function setAuthForAllAxiosRequests() {
  interceptorFor401s = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const urlsToIgnore = ["/tokens"];
      const sendToSignInPage =
        error &&
        error.response &&
        error.response.status == 401 &&
        error.response.config &&
        !urlsToIgnore.includes(error.response.config.url);
      if (sendToSignInPage) {
        const path = router.currentRoute.path;
        console.log(`401 interceptor: router.currentRoute.path=${path}`);
        if (path != "/sign-out" && path != "/401") {
          router.push("/401", () => {});
        }
      }
      return Promise.reject(error);
    }
  );
}

function removeAuthFromAxiosRequests() {
  try {
    if (interceptorFor401s)
      axios.interceptors.request.eject(interceptorFor401s);
  } catch {}
}

function createCookie(name, token, expiryInHours) {
  expiryInHours = expiryInHours || 24;
  const expiryInMilliseconds = expiryInHours * 60 * 60 * 1000;
  let d = new Date();
  d.setTime(d.getTime() + expiryInMilliseconds);
  const expires = d.toUTCString();
  document.cookie = `${name}=${token};expires=${expires};samesite=Strict;`;
}

function deleteCookie(name) {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;samesite=Strict;`;
}
