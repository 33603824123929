<template> </template>

<script>
import { userService } from "../store/users/service";

export default {
  beforeRouteEnter(to, from, next) {
    userService.logout();
    next("/sign-in");
  },
};
</script>
