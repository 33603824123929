<template>
  <div style="height: 5px">
    <v-progress-linear v-if="show" indeterminate />
  </div>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
