<template>
  <div>
    <v-alert v-show="showError" type="error">{{ signInError }}</v-alert>
    <our-busy-indicator :show="!loaded" />
    <v-form ref="form">
      <v-card>
        <v-card-text>
          <v-container grid-list-xl pa-3>
            <v-layout wrap>
              <v-flex class="sm12 py-1">
                <v-text-field
                  type="text"
                  label="Email address"
                  title="Required"
                  :rules="[rules.required]"
                  v-model="username"
                />
              </v-flex>
              <v-flex class="sm12 pt-1 pb-0">
                <v-text-field
                  type="password"
                  label="Password"
                  title="Required"
                  :rules="[rules.required]"
                  v-model="password"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-flex grow class="text-sm-right pr-4 my-3">
            <v-btn
              type="submit"
              class="ml-0 mr-2"
              color="primary"
              :disabled="isSigningIn"
              @click.prevent="signIn"
            >
              Sign in
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { userService } from "../store/users/service";

export default {
  data() {
    return {
      loaded: true,
      username: "",
      password: "",
      isSigningIn: false,
      signInError: "",
      showError: false,
      rules: {
        required: (value) =>
          value === null || value === undefined || value === ""
            ? "Required"
            : true,
      },
      environments: [
        {
          name: "Production",
          names: ["production"],
          isProduction: true,
          supportedSubmitMethods: [],
        },
        {
          name: "Testing",
          names: ["local", "development", "test", "preproduction", "training"],
          isProduction: false,
          supportedSubmitMethods: ["get", "post", "put", "delete", "options"],
        },
      ],
    };
  },
  methods: {
    signIn() {
      if (!this.$refs.form.validate()) return;

      this.loaded = false;
      this.isSigningIn = true;
      this.showError = false;

      const data = JSON.stringify({
        email: this.username,
        password: this.password,
      });

      let tokenUrl = "/tokens";

      axios
        .post(tokenUrl, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          let details = response.data;
          const environment =
            details?.environment?.toLowerCase() ?? "production";
          details["environmentInfo"] =
            this.environments.find((e) => e.names.includes(environment)) ??
            this.environments[0];
          userService.login(details);
          this.$router.push("/");
        })
        .catch((error) => {
          if (error.response.data.title) {
            this.signInError = error.response.data.title;
          } else if (error.request) {
            this.signInError =
              "Unable to connect to PRISMS. Please contact PRISMS Helpdesk.";
          } else {
            this.signInError =
              "An error has occurred when signing in. Please try again or contact PRISMS Helpdesk.";
          }
          this.showError = true;
          this.isSigningIn = false;
        })
        .finally(() => (this.loaded = true));
    },
  },
};
</script>
